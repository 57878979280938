<template>
  <div id="especialidades" class="especialidades">
    <v-container grid-list-xl fluid>
      <v-layout wrap>
        <v-flex lg12 md12>
          <v-card class="mb-4">
            <v-toolbar :color="custom_template ? 'primary' : 'info'" dark flat dense cad>
              <v-toolbar-title class="subheading">{{ $t('agenda.especialidades') }}</v-toolbar-title>
              <v-spacer />
            </v-toolbar>
            <v-divider />
            <v-card-text class="">
              <v-layout class="filtroInforme">
                <v-flex xs12 sm4 lg4>
                  <v-select
                    v-model="especialidad"
                    :items="especialidades"
                    :label="$t('agenda.especialidad')"
                    auto
                    required
                    item-text="name"
                    item-value="id"
                    @change="cambiaEspecialidad"
                  />
                </v-flex>
                <v-flex xs6 sm4 lg4>
                  <v-select
                    v-model="centro"
                    :items="centros"
                    :label="$t('common.centro')"
                    auto
                    required
                    item-text="name"
                    item-value="id"
                    @change="cambiaEspecialidad"
                  />
                </v-flex>
                <v-flex xs6 sm4 lg4>
                  <v-text-field
                    v-model="duracion"
                    :label="$t('common.duracion')"
                    disabled="disabled"
                    type="number"
                    min="1"
                    max="1440"
                    suffix="min."
                    append-icon="mdi-clock-outline"
                  />
                </v-flex>
              </v-layout>
              <span v-if="especialidad > 0">
                <tabla-semanal
                  :selectors="profesionales"
                  :label_selector="$t('common.centro')"
                  :loading="loading"
                  :headers-tabla="tabla.headers"
                  :items-tabla="tabla.body"
                />
                <v-layout class="filtroInforme">
                  <v-flex xs12 class="text-center">
                    <v-btn :disabled="disabledButton" color="green" dark @click="guardarAgenda()">
                      {{ $t('common.save') }}
                    </v-btn>
                  </v-flex>
                </v-layout>
              </span>
              <v-alert v-else color="grey" :value="true">
                {{ $t('agenda.seleccione_especialidad') }}
              </v-alert>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
    <!-- Snackbar para mostrar avisos -->
    <v-snackbar
      v-model="snackbar"
      :timeout="timeout"
      :color="alerta.color"
      top="top"
      :multi-line="mode === 'multi-line'"
      :vertical="mode === 'vertical'"
    >
      <v-icon v-if="alerta.icono !== ''">{{ alerta.icono }}</v-icon
      >&nbsp;{{ alerta.texto }}
    </v-snackbar>

    <div class="text-center">
      <v-dialog v-model="dialogSalirSinGuardar" width="500">
        <v-card>
          <v-card-title class="headline lighten-2">{{ $t('common.titulo_salir_sin_guardar') }}</v-card-title>
          <v-divider />
          <v-card-text>{{ $t('common.salir_sin_guardar') }}</v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn color="primary" dark text @click="dialogSalirSinGuardar = false">{{ $t('common.cancel') }}</v-btn>
            <v-btn color="primary" dark text @click="confirmarSalir">{{ $t('common.confirm') }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import TablaSemanal from '@/components/common/TablaSemanal.vue';
import { nameEnv, especialidadUrl, calendarioEspecialidadIndex, getHeader } from '../../config/config';

export default {
  components: {
    TablaSemanal,
  },
  data: () => ({
    especialidades: [],
    especialidad: 0,
    centros: [
      { id: 1, name: 'Madrid' },
      { id: 2, name: 'Barcelona' },
    ],
    centro: 1,
    profesionales: [],
    duracion: 0,
    tabla: { headers: [], body: [] },
    dialogSalirSinGuardar: false,
    pageNext: {},
    authUser: JSON.parse(window.localStorage.getItem('auth_ehealth')),
    loading: true,
    agenda_id: 0,
    dias: [],
    menu: false,
    time: null,
    agendaModificada: false,
    disabledButton: false,
    arrayEvents: null,
    snackbar: false,
    timeout: 5000,
    mode: '',
    alerta: {
      texto: '',
      icono: '',
      color: 'info',
    },
    steps: [
      { target: '[data-v-step="0"]', content: '' },
      { target: '[data-v-step="1"]', content: '' },
    ],
    custom_template:
      window.localStorage.getItem('auth_ehealth') &&
      typeof JSON.parse(window.localStorage.getItem('auth_ehealth'))['colorHexadecimal'] !== 'undefined' &&
      JSON.parse(window.localStorage.getItem('auth_ehealth'))['colorHexadecimal'] !== ''
        ? true
        : false,
    colorPrimario:
      window.localStorage.getItem('auth_ehealth') &&
      typeof JSON.parse(window.localStorage.getItem('auth_ehealth'))['color'] !== 'undefined' &&
      JSON.parse(window.localStorage.getItem('auth_ehealth'))['color'] !== ''
        ? JSON.parse(window.localStorage.getItem('auth_ehealth'))['color']
        : 'blue',
    es_doctorclic: nameEnv === 'doctorclic',
  }),
  watch: {},

  mounted: function() {
    const tieneAcceso = this.es_doctorclic && this.authUser.rol === 4 ? true : false;
    if (!tieneAcceso) {
      this.$router.push('/dashboard');
    }
  },

  created() {
    this.$http
      .post(
        calendarioEspecialidadIndex,
        { user_id: this.authUser.id, accion: 'index', language: this.$i18n.locale },
        { headers: getHeader() }
      )
      .then(response => {
        if (response.status === 200 && response.data.estado === 1) {
          this.loading = false;
          this.especialidades = response.data.especialidades;
          this.profesionales = response.data.profesionales;
          this.profesionales.unshift({ id: 0, name: '-' });
        }
      });
  },

  methods: {
    cambiaEspecialidad() {
      this.loading = true;
      this.$http
        .post(
          especialidadUrl,
          {
            user_id: this.authUser.id,
            accion: 'seleccionaEspecialidad',
            especialidad_id: this.especialidad,
            centro_id: this.centro,
          },
          { headers: getHeader() }
        )
        .then(response => {
          if (response.status === 200 && response.data.estado === 1) {
            this.loading = false;
            this.tabla = response.data.agenda;
            this.duracion = response.data.duracion;
          }
        });
    },

    guardarAgenda() {
      if (!this.disabledButton) {
        this.disabledButton = true;
        this.$http
          .post(
            especialidadUrl,
            {
              user_id: this.authUser.id,
              accion: 'guardar',
              agenda: this.tabla.body,
              especialidad_id: this.especialidad,
              centro_id: this.centro,
            },
            { headers: getHeader() }
          )
          .then(response => {
            if (response.status === 200 && response.data.estado === 1) {
              this.alerta.texto = this.$t('success.save_agenda');
              this.alerta.color = 'success';
              this.alerta.icono = 'mdi-check';
              this.agendaModificada = false;
            } else {
              this.alerta.texto = this.$t('errors.try_again');
              this.alerta.color = 'error';
              this.alerta.icono = 'mdi-alert-circle';
            }
            this.snackbar = true;
            this.disabledButton = false;
          });
      }
      this.disabledButton = false;
    },

    confirmarSalir() {
      this.dialogSalirSinGuardar = false;
      this.pageNext();
    },
  },

  modificandoDatos() {
    this.agendaModificada = true;
  },

  beforeRouteLeave(to, from, next) {
    if (this.agendaModificada && to['name'] !== 'Login') {
      this.dialogSalirSinGuardar = true;
      this.pageNext = next;
      next(false);
    } else {
      next();
    }
  },
};
</script>
<style lang="stylus" scoped>
.media
  &-cotent--wrap

  &-menu
    min-width: 260px
    border-right: 1px solid #eee
    min-height: calc(100vh - 50px - 64px);
  &-detail
    min-width:300px
    border-left:1px solid #eee
</style>
<style scoped lang="css">
.bounce-enter-active {
  animation: bounce-in 0.5s;
}
.bounce-leave-active {
  animation: bounce-in 0.5s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}
.v-picker__title primary {
  display: none !important;
}

.tabla_cal th {
  text-align: center;
  color: #27b0e9;
  font-weight: 100;
  font-size: 12px;
}
</style>
